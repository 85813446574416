<template>
	<div class="container buffet-page" v-loading="loading">
		<div v-if="visibleBuffets.length === 0 && loading === false">
			{{$t('buffet-list-empty')}}
		</div>		
		
		<div class="buffet-list">
			<buffet-list-item
				v-for="buffet in visibleBuffets"
				:key="buffet.id"
				:value="buffet"				
				@click="buffet.open && buffetClicked(buffet.id)"
			></buffet-list-item>
		</div>

		<confirm
				v-model="confirmVisible"
				@continue="onConfirmContinue"
		></confirm>
	</div>
</template>
<script>
	import BuffetListItem from "../../components/buffet-list-item.vue";
	import Confirm from "../../components/confirm.vue";
	import {getBuffets} from "../../functions/api/content";
	import store from "@/store";

	export default {
		components: {BuffetListItem, Confirm},
		beforeRouteEnter(to, from, next) {
			if(!store.state.selectedBuffetGroup) {
				return next({name: 'sector-selector'});
			}
			next();
		},
		data() {
			return {
				loading: null,
				buffetSearchString: '',
				confirmVisible: false,
				tmpBuffetId: ''
			}
		},
		computed: {
			buffetGroupToLocations() {
				return this.$store.state.sectorLocationPairs;
			},
			buffets() {
				return this.$store.state.buffets;
			},
			cart() {
				return this.$store.state.cart;
			},
			visibleBuffets() {
				return this.$store.getters.visibleBuffets;
			},
			allBuffetsClosed() {
				return !this.buffets.find(buffet => buffet.open);
			}
		},
		created() {
			this.$store.commit('setSkyboxTitle', 'buffet-list-title')
			this.$store.commit('setSkyboxText', '')
			this.$store.commit('setSkyboxLink', '')
			this.$store.commit('setSkyboxLinkText', '')
			this.$store.commit('showNavigation');

			this.refreshBuffets()
				.then(() => {
					if(this.allBuffetsClosed) {
						this.$router.replace('/app-closed');
					}
				});

			const visibleBuffets = this.$store.getters.visibleBuffets;
			if (visibleBuffets.length === 1) {
				this.buffetClicked(visibleBuffets[0].id);
			}

			this.$store.dispatch('loadPendingOrders');
		},
		methods: {
			refreshBuffets() {
				this.loading = true;
				return getBuffets(this.$store.state.mvmId).then(res => {
					this.$store.commit('setBuffets', res);
					this.loading = false;
				}).catch(err => {
					this.loading = false;
					console.error(err);
				})
			},
			buffetClicked(buffetId) {
				if(this.cart && this.cart.buffetId !== buffetId && this.cart.items.length > 0) {
					this.confirmVisible = true;
					this.tmpBuffetId = buffetId;
					return;
				}
				
				this.selectBuffet(buffetId);
			},
			selectBuffet(buffetId) {
				this.tmpBuffetId = '';
				this.$store.dispatch('selectBuffet', buffetId);
				this.$router.push('/buffet-subpage');
			},
			onConfirmContinue() {
				this.$store.dispatch('dropCart');
				this.selectBuffet(this.tmpBuffetId);
			}
		}
	}
</script>
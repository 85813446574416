<script>
	import SmartImage from './smart-image';

	export default {
		components: {SmartImage},
		props: ['value'],
		data() {
			return {
				setTimeout: null,
				offsetWidth: 0,
				scrollLeft: 0,
				selectedImageIndex: 0,
			}
		},
		mounted() {
			this.updateOffsetWidth();
			window.addEventListener('resize', this.updateOffsetWidth);
		},
		watch: {
			selectedImageIndex(index) {
				this.scrollLeft = index * this.$el.offsetWidth;
				this.$refs.scrollableParent.scrollTo({ left: this.scrollLeft, behavior: 'smooth' });
			},
			// can change when scrollbar appear
			'this.$root.$el.scrollHeight'() {
				this.updateOffsetWidth();
			}
		},
		destroyed() {
			window.removeEventListener('resize', this.updateOffsetWidth);
		},
		methods: {
			updateOffsetWidth() {
				this.offsetWidth = this.$el.offsetWidth;
			},
			onScroll(e) {
				clearTimeout(this.setTimeout);
				this.setTimeout = setTimeout(() => {
					const scrollingLeft = e.target.scrollLeft > this.scrollLeft,
						exactlyOnAnItem = e.target.scrollLeft % this.offsetWidth === 0,
						modifier = scrollingLeft || exactlyOnAnItem ? 0 : -1;
					this.paginateTo(Math.ceil(e.target.scrollLeft / this.offsetWidth) + modifier);
				}, 200);
			},
			paginateTo(index) {
				this.selectedImageIndex = index;
			}
		}
	}
</script>
<template>
	<div class="image-carousel">
		<div
				class="carousel-wrapper"
				:style="{'--carousel-outer-width': offsetWidth + 'px'}"
				@scroll="onScroll"
				ref="scrollableParent"
		>
			<div class="carousel-inner">
			<smart-image
					v-for="image_key in value"
					:key="image_key"
					:img_key="image_key"
					size="large"/>
			</div>
		</div>
		<div class="navigation" v-if="value && value.length > 1">
			<span
					v-for="(image_key, index) in value"
					:key="image_key"
					class="dot"
					:class="{'active': selectedImageIndex === index}"
					@click.stop="paginateTo(index)"></span>
		</div>
	</div>
</template>
<style scoped>
	.image-carousel {
		width: 100%;
		position: relative;
	}
	.carousel-wrapper {
		overflow-x: auto;
		display: flex;
	}
	.carousel-inner {
		display: inline-flex;
	}
	.smart-image {
		display: inline-block;
		width: var(--carousel-outer-width);
		max-height: 400px;
	}

	.navigation {
		position: absolute;
		bottom: 0;
		width: 100%;
		height: 30px;
		text-align: center;
		background: linear-gradient(transparent, #22222288);
		padding-top: 20px;
	}
	.navigation .dot {
		display: inline-block;
		width: 8px;
		height: 8px;
		background: #FFFFFF;
		border: 1px solid #ffffff77;
		border-radius: 50%;
		transition: all 200ms ease-in;
	}
	.navigation .dot:not(:last-of-type) {
		margin-right: 10px;
	}
	.navigation .dot.active {
		background: var(--theme-color-3);
	}
</style>
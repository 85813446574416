<script>
	export default {
		props: ['value'],
		data() {
			return {
				showModal: false
			}
		},
		methods: {
			close() {
				this.$emit('cancel');
				this.$emit('input', false);
			},
			continueClicked() {
				this.showModal = false;
				this.$emit('continue');
				this.$emit('input', false);
			}
		}
	}
</script>
<template>
	<transition name="fade" @after-enter="showModal = true">
		<div class="confirm confirm-bg" v-if="value" @click.self="showModal = false">
			<transition name="slide-fade-up" @after-leave="close">
				<div class="modal-outer" v-if="showModal">
					<div class="modal">
						<div class="icon-row">
							<div class="icon-circle">
								<span class="icon icon-info"></span>
							</div>
						</div>
						<h3>{{$t('drop-cart-confirm-title')}}</h3>
						<p class="description">{{$t('drop-cart-confirm-description')}}</p>
						<div class="btn gold-button large" @click="showModal = false">
							{{$t('drop-cart-confirm-btn-primary')}}
						</div>
						<div class="btn plain-button" @click="continueClicked">
							{{$t('drop-cart-confirm-btn-secondary')}}
						</div>
					</div>
				</div>
			</transition>
		</div>
	</transition>
</template>
<style scoped>
	.confirm-bg {
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: #000000ac;
		z-index: 9999;
		display: flex;
	}
	.modal-outer {
		padding: 20px;
		margin: auto;
		width: 100%;
		max-width: 400px;
	}
	.modal {
		background-color: white;
		border-radius: 10px;
		padding: 40px 22px;
		box-sizing: border-box;
	}
	.confirm:not(.visible) .modal {
	}
	.icon-row {
		margin-bottom: 20px;
		text-align: center;
	}
	.icon-row .icon-circle {
		width: 74px;
		height: 74px;
		display: flex;
		margin: auto;
		background-color: var(--danger-color);
		border-radius: 50%;
		color: white;
		font-size: 38px;
	}
	.icon-row .icon-circle .icon {
		margin: auto;
	}
	h3 {
		margin-bottom: 13px;
		text-align: center;
	}
	.description {
		margin-bottom: 26px;
	}
	.btn {
		text-transform: uppercase;
	}
	.gold-button {
		margin-bottom: 26px;
	}
</style>
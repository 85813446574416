<script>
	import ImageCarousel from './image-carousel';

	export default {
		components: {ImageCarousel},
		props: ['value']
	}
</script>
<template>
	<div :class="{'buffet-list-item': true, 'active': value.open, 'inactive': !value.open}" v-on="$listeners">
        <div class="overlay"></div>
        <div class="image">
			<image-carousel :value="value.image_keys"></image-carousel>
        </div>

        <div class="details">
            <h3>{{value.title}}</h3>
            
			<span class="type">
				{{ $t('buffet-title') }}
			</span>
            <p class="description">
                {{value.description}}
            </p>

        </div>
    </div>

</template>
<style>
	/* safari workaround */
	.buffet-list-item .image-carousel .navigation {
		border-radius: 10px;
	}
</style>
<style scoped>
	.buffet-list-item {
		margin-bottom: 30px;
		position: relative;
	}
	.buffet-list-item .closed-text {
		font-size: 20px;
		font-weight: bold;
		position: absolute;
		top: 5px;
		right: 5px;
	}
	.image-carousel {
		margin-bottom: 18px;
		border-radius: 10px;
		overflow: hidden;
	}
	.texts .name {
		margin-bottom: 10px;
	}
	.texts p {
		margin-bottom: 6px;
	}
</style>